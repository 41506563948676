<template>
  <div class="import-books">
    <b-card>
      <error :err.sync="error" :variant="success ? 'success' : 'danger'" />
      <b-alert variant="danger" :show="!!errors.length">
        <ul class="mb-0" v-for="(error, i) in errors" :key="i">
          <li>{{ error }}</li>
        </ul>
      </b-alert>
      <form-generator
        :data="data"
        :elements="elements"
        :handleUpdate="({ key }, v) => (data[key] = v)"
        v-if="!loading"
      />
      <template v-else>
        <b-progress
          :value="progress"
          show-progress
          animated
          height="1.6rem"
        ></b-progress>
        <h3 class="text-center mt-5">
          {{ $t("uploading") }}
        </h3>
      </template>
    </b-card>
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";

export default {
  name: "import-books",
  components: {
    FormGenerator
  },
  data() {
    return {
      data: {
        file: null
      },
      loading: false,
      error: null,
      progress: 0,
      success: null,
      errors: []
    };
  },
  computed: {
    elements() {
      return [
        {
          type: "dropzone",
          events: {
            input: files => {
              if (files.length) {
                this.data.file = files[0];
              } else {
                this.data.file = null;
              }
            }
          }
        },
        {
          type: "button",
          text: "import",
          className: "px-4 float-right",
          events: {
            click: this.import
          },
          attrs: {
            loading: this.loading
          }
        }
      ];
    }
  },
  methods: {
    async import() {
      this.error = "";
      this.errors = [];
      this.success = false;

      if (!this.data.file) {
        this.error = this.$t("validation-errors.upload-file");
      } else {
        this.loading = true;

        try {
          await this.$store.dispatch("books/importCSV", {
            file: this.data.file,
            onUploadProgress: e => {
              this.progress = (e.loaded / e.total) * 100;
            }
          });
          this.success = true;
          this.error = this.$t("imported");
          this.progress = 0;
        } catch (err) {
          this.error = this.errorRes(err);
          this.errors = err.response.data?.data?.errors?.map(
            err => `Row ${err.row_number} - ${err.error}`
          );

          console.error(err);
        }

        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
